import { Injectable } from '@angular/core';
import {
  EAuctionRejectionReason,
  ETransportationTaskState,
  IAccountGroup,
  IAdminAuctionView,
  IAdminBuyerView,
  IAdminSellerView,
  IAdminTransportationTaskView,
  IAuction,
  IAuctionBid,
  IAuctionBiddingAgentTask,
  IAuctionComplaint,
  IAuctionComplaintPossibility,
  IAuctionFilter,
  IAuctionMistake,
  IAuthenticationActivity,
  ICreateCreditNoteXMLPayload,
  ICreateCreditNoteXMLResponse,
  ICreateInvoiceViaFormRequest,
  ICreateVoucherPayload,
  IEmailTemplateSettings,
  IGeneralBusinessTerms,
  IGeneralUser,
  IGeneralUserFilter,
  IIdentityProviderUser,
  IInternalAuctionUpdate,
  IInternalSellerView,
  IInternalUser,
  IInternalUserFilterRequest,
  IInvoice,
  ILastOfferBySeller,
  IPage,
  IPrebookedService,
  IResourceCountResult,
  ISellerUser,
  ISellerUserFilter,
  ISurvey,
  ITestMailSendRequest,
  ITransportationProviderRate,
  ITransportationProviderTaskView,
  ITransportationProviderVehicle,
  ITransportationTask,
  ITransportationTaskFilterRequest,
  ITransporterUser,
  IUserDevice,
  IVoucher,
  IVoucherAssignment,
  USER_PRIVILEGES,
  Validation,
} from '@caronsale/cos-models';
import { map, Observable } from 'rxjs';
import { CosCoreClient } from '@cosCoreServices/core-client/cos-core-client.service';
import { TranslateService } from '@ngx-translate/core';
import { AuctionUtils } from '@cosUtils/business';

const DEFAULT_LIMIT_FOR_NUMBER_OF_SUBORDINATES = 500;

@Injectable({
  providedIn: 'root',
})
export class CosInternalAdminClientService {
  public constructor(
    private cosClient: CosCoreClient,
    private translateService: TranslateService,
  ) {}

  public createAuctionSuccessFeeInvoiceForSeller(auction: IAuction): Observable<IInvoice> {
    return this.cosClient.requestWithPrivileges('post', `/admin/invoice/auction-success-fee/${auction.uuid}`, undefined);
  }

  public createAuctionListingFeeInvoiceForSeller(auction: IAuction): Observable<IInvoice> {
    return this.cosClient.requestWithPrivileges('post', `/admin/invoice/auction-listing-fee/${auction.uuid}`, undefined);
  }

  public getAuctionsPage(filter: IAuctionFilter = {}): Observable<IPage<IAdminAuctionView>> {
    filter = {
      ...filter,
      states: Object.values(filter.states ?? {}).some(Boolean) ? filter.states : null,
    };

    return this.cosClient.requestV2WithPrivileges('get', `/admin/auction?filter=${this.cosClient.encodeParamObject(filter)}`);
  }

  public getAuction(uuid: string): Observable<IAdminAuctionView> {
    const language = this.translateService.currentLang;
    return this.cosClient.requestWithPrivileges('get', `/admin/auction/${uuid}?language=${language}`);
  }

  public assignCosTransportationTaskToTransportationProvider(
    taskUuid: string,
    userUuid: string,
    request: Pick<ITransportationTask, 'additionalInformation'> = null,
  ): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/transportation/provider/${userUuid}/cos-task/${taskUuid}/assign`, request);
  }

  public updateTransportationTaskState(task: ITransportationProviderTaskView, state: ETransportationTaskState, user: ITransporterUser): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/transportation/task/${task.uuid}/provider/${user.uuid}/state/${state}`, undefined);
  }

  public unassignTask(user: ITransporterUser, task: ITransportationProviderTaskView): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/transportation/provider/${user.uuid}/task/${task.uuid}/unassign`, undefined);
  }

  public getAvailableTransportationTasks(): Observable<IAdminTransportationTaskView[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/transportation/tasks/available`);
  }

  public getTransportationProviderTasks(user: ITransporterUser, filter: ITransportationTaskFilterRequest): Observable<ITransportationProviderTaskView[]> {
    return this.cosClient.requestWithPrivileges(
      'get',
      `/internal/transportation/provider/${user.uuid}/tasks?filter=${this.cosClient.encodeParamObject(filter)}`,
    );
  }

  public getTransportationProviderVehicles(user: ITransporterUser): Observable<ITransportationProviderVehicle[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/transportation/provider/${user.uuid}/vehicles`);
  }

  public createTransportationProviderVehicle(user: ITransporterUser, vehicle: ITransportationProviderVehicle): Observable<ITransportationProviderVehicle> {
    return this.cosClient.requestWithPrivileges('put', `/internal/transportation/provider/${user.uuid}/vehicles`, vehicle);
  }

  public updateTransportationProviderVehicle(user: ITransporterUser, vehicle: ITransportationProviderVehicle): Observable<ITransportationProviderVehicle> {
    return this.cosClient.requestWithPrivileges('post', `/internal/transportation/provider/${user.uuid}/vehicles/${vehicle.uuid}`, vehicle);
  }

  public deleteTransportationProviderVehicles(user: ITransporterUser, vehicleUuid: string): Observable<ITransportationProviderVehicle[]> {
    return this.cosClient.requestWithPrivileges('delete', `/internal/transportation/provider/${user.uuid}/vehicles/${vehicleUuid}`);
  }

  public getTransportationProviderRates(user: ITransporterUser): Observable<ITransportationProviderRate[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/transportation/provider/${user.uuid}/transportation-rate`);
  }

  public removeTransportationProviderRate(user: ITransporterUser, rateUUid: string): Observable<void> {
    return this.cosClient.requestWithPrivileges('delete', `/internal/transportation/provider/${user.uuid}/transportation-rate/${rateUUid}`);
  }

  public setTransportationProviderRate(user: ITransporterUser, rateToAdd: ITransportationProviderRate): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/transportation/provider/${user.uuid}/transportation-rate`, rateToAdd);
  }

  public upsertTransportationTaskMemo(task: ITransportationProviderTaskView): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/transportation/task/${task.uuid}/memo`, task.memo);
  }

  public getBuyerUsers(filter: IGeneralUserFilter): Observable<IPage<IAdminBuyerView>> {
    return this.cosClient.requestV2WithPrivileges('get', `/internal/user-account/buyer?filter=${this.cosClient.encodeParamObject(filter)}`);
  }

  public countBuyerUsers(): Observable<IResourceCountResult> {
    return this.cosClient.requestWithPrivileges('get', `/admin/user-account/buyer/_all/_count`);
  }

  public changePasswordForInternalUser(user: IInternalUser, newPassword: string): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/admin/user-account/internal/${user.uuid}/password`, {
      currentPassword: null,
      newPassword,
    });
  }

  public createInternalUser(newInternalUser: IInternalUser): Observable<void> {
    // TODO: Use ModelUtils
    if (null !== newInternalUser.privileges.match(USER_PRIVILEGES.INTERNAL_USER)) {
      return this.cosClient.requestWithPrivileges('put', `/admin/user-account/internal/key-account-management`, newInternalUser);
    } else {
      return this.cosClient.requestWithPrivileges('put', `/admin/user-account/internal/admin`, newInternalUser);
    }
  }

  public deleteInternalUser(userUuid: string): Observable<void> {
    return this.cosClient.requestWithPrivileges('delete', `/admin/user-account/internal/${userUuid}`);
  }

  public getInternalUser(internalUserUuId: string): Observable<IInternalUser> {
    return this.cosClient.requestWithPrivileges('get', `/admin/user-account/internal/${internalUserUuId}`);
  }

  public getInternalUsers(filter: IInternalUserFilterRequest): Observable<IInternalUser[]> {
    return this.cosClient.requestWithPrivileges('get', `/admin/user-account/internal?filter=${this.cosClient.encodeParamObject(filter)}`);
  }

  public getBuyerUserDevices(uuid: string): Observable<IUserDevice[]> {
    return this.cosClient.requestWithPrivileges('get', `/admin/user-account/buyer/${uuid}/user-device`);
  }

  public getSellerUserDevices(uuid: string): Observable<IUserDevice[]> {
    return this.cosClient.requestWithPrivileges('get', `/admin/user-account/seller/${uuid}/user-device`);
  }

  public makeSellerUserSupervisor(sellerUser: ISellerUser): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/admin/user-account/seller/${sellerUser.uuid}/supervisor`, {});
  }

  public removeSupervisorPrivilegeFromDealershipUser(dealershipUser: ISellerUser): Observable<void> {
    return this.cosClient.requestWithPrivileges('delete', `/admin/user-account/seller/${dealershipUser.uuid}/supervisor`, {});
  }

  public updateSellerUserState(sellerUser: ISellerUser): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/admin/user-account/seller/${sellerUser.uuid}/state`, {
      // TODO: Introduce model?
      state: sellerUser.state,
    });
  }

  public reinsertAuction(auction: IAuction): Observable<IAuction> {
    return this.cosClient.requestWithPrivileges('put', `/admin/auction/${auction.uuid}/clone`, auction, this.cosClient.generateIdempotencyHeader());
  }

  public sellAuctionToBuyer(
    auction: IAuction,
    buyerUser: IGeneralUser,
    bid: IAuctionBid = null,
    buyerFeeDiscount?: number,
    discountReason?: string,
    discountReasonComment?: string,
  ): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/admin/auction/${auction.uuid}/sell-to/${buyerUser.uuid}`, {
      newBid: bid,
      successParams: {
        purchaseInvoiceCreationParams: {
          applyBuyerFeeDiscount: buyerFeeDiscount,
        },
        discountReason,
        discountReasonComment,
      },
    });
  }

  public changeBuyerForAuction(auction: IAuction, buyerUser: IGeneralUser): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/admin/auction/${auction.uuid}/change-buyer-to/${buyerUser.uuid}`, undefined);
  }

  public confirmAuctionBelowMinAsk(
    auction: IAuction,
    manualRenegotiationFee?: number,
    buyerFeeDiscount?: number,
    discountReason?: string,
    discountReasonComment?: string,
  ): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/auction/${auction.uuid}/confirm`, {
      purchaseInvoiceCreationParams: {
        applyManualRenegotiationFee: manualRenegotiationFee || 0,
        applyBuyerFeeDiscount: buyerFeeDiscount || 0,
      },
      discountReason,
      discountReasonComment,
    });
  }

  public disableAuction(auction: IAuction): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/admin/auction/${auction.uuid}/disable`, auction);
  }

  public rejectRenegotiationDisableOnly(uuid: string, reason: EAuctionRejectionReason, competitorName?: string): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/auction/${uuid}/reject/disable`, {
      rejectionReason: reason,
      rejectionReasonCompetitorName: competitorName ?? null,
    });
  }

  public rejectRenegotiationDisableAndRedraft(uuid: string, reason: EAuctionRejectionReason, note: string): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/auction/${uuid}/reject/redraft`, {
      rejectionReason: reason,
      rejectionReasonNote: note,
    });
  }

  public publishDraftedAuction(auction: IAuction, skipReview: boolean): Observable<void> {
    skipReview = Validation.isInvalidBoolean(skipReview) ? false : skipReview;

    return this.cosClient.requestWithPrivileges('post', `/admin/auction/${auction.uuid}/publish?skipReview=${skipReview}`);
  }

  public updateAuction(auction: IInternalAuctionUpdate): Observable<void> {
    AuctionUtils.syncVatReportable(auction);
    return this.cosClient.requestWithPrivileges('post', `/internal/auction/${auction.uuid}`, auction);
  }

  public updateSellerUser(sellerUser: ISellerUser): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/admin/user-account/seller/${sellerUser.uuid}`, sellerUser);
  }

  public getAuctionsExpiringInTheNextDays(): Observable<Array<{ dateString: string; expiringAuctions: number }>> {
    return this.cosClient.requestWithPrivileges('get', `/admin/auction/expiring`);
  }

  public getSellerUsers(filter: ISellerUserFilter): Observable<IAdminSellerView[]> {
    return this.cosClient.requestWithPrivileges('get', `/admin/user-account/seller?filter=${this.cosClient.encodeParamObject(filter)}`);
  }

  public getSellerUsersAsKam(filter: ISellerUserFilter): Observable<IInternalSellerView[]> {
    return this.cosClient.requestWithPrivileges('get', `/key-account-management/dealership?filter=${this.cosClient.encodeParamObject(filter)}`);
  }

  public getDealershipSupervisorUserData(filter: ISellerUserFilter): Observable<IAdminSellerView[]> {
    return this.cosClient.requestWithPrivileges('get', `/admin/user-account/seller/supervisor/_all?filter=${this.cosClient.encodeParamObject(filter)}`);
  }

  public getSubordinatesForSupervisor(supervisorUser: ISellerUser, filter?: ISellerUserFilter): Observable<IAdminSellerView[]> {
    filter = filter || {
      limit: DEFAULT_LIMIT_FOR_NUMBER_OF_SUBORDINATES,
      offset: 0,
      sortBy: [
        {
          field: 'lastName',
          desc: false,
        },
        {
          field: 'firstName',
          desc: false,
        },
      ],
    };

    return this.cosClient.requestWithPrivileges(
      'get',
      `/admin/user-account/seller/supervisor/${supervisorUser.uuid}/_all` + `?filter=${this.cosClient.encodeParamObject(filter)}`,
    );
  }

  public getAccountGroupsList(): Observable<IAccountGroup[]> {
    return this.cosClient.requestWithPrivileges('get', '/internal/account/groups');
  }

  public addSubordinateForSupervisor(supervisorUser: ISellerUser, subordinate: ISellerUser): Observable<void> {
    return this.cosClient.requestWithPrivileges('put', `/admin/user-account/seller/supervisor/${supervisorUser.uuid}/${subordinate.uuid}`, undefined);
  }

  public removeSubordinateForSupervisor(supervisorUser: ISellerUser, subordinate: ISellerUser): Observable<void> {
    return this.cosClient.requestWithPrivileges('delete', `/admin/user-account/seller/supervisor/${supervisorUser.uuid}/${subordinate.uuid}`);
  }

  public countSellerUsers(): Observable<IResourceCountResult> {
    return this.cosClient.requestWithPrivileges('get', `/admin/user-account/seller/_all/_count`);
  }

  public createPurchaseInvoiceForAuction(auction: IAuction): Observable<void> {
    return this.cosClient.requestWithPrivileges('put', `/admin/invoice/${auction.uuid}/purchase/stripe`, undefined);
  }

  public createTransportationInvoiceForAuction(auction: IAuction): Observable<void> {
    return this.cosClient.requestWithPrivileges('put', `/admin/invoice/${auction.uuid}/transportation/stripe`, undefined);
  }

  public getAuctionsWithOpenPurchaseInvoicing(): Observable<IAdminAuctionView[]> {
    return this.cosClient.requestWithPrivileges('get', `/admin/invoice/auction/open/purchase`);
  }

  public getAuctionsWithOpenTransportationInvoicing(): Observable<IAdminAuctionView[]> {
    return this.cosClient.requestWithPrivileges('get', `/admin/invoice/auction/open/transportation`);
  }

  // TODO: Allow memo of transportation invoices

  public getSellerKeyAccountsWithStatsForKAM(kamUserUuid: string): Observable<IInternalSellerView[]> {
    return this.cosClient.requestWithPrivileges('get', `/admin/user-account/internal/${kamUserUuid}/key-account/dealership/stats/_all`);
  }

  public assignInternalUserToSellerUser(dealership: ISellerUser, kamUser: IInternalUser): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/admin/user-account/seller/${dealership.uuid}/key-account/${kamUser.uuid}`);
  }

  public removeInternalUsersFromSellerUser(dealershipUser: ISellerUser): Observable<void> {
    return this.cosClient.requestWithPrivileges('delete', `/admin/user-account/seller/${dealershipUser.uuid}/key-account`);
  }

  public getSupplyManagerForSellerUser(sellerUser: ISellerUser): Observable<IInternalUser> {
    return this.cosClient.requestWithPrivileges('get', `/admin/user-account/seller/${sellerUser.uuid}/key-account`);
  }

  public getSellerUser(sellerUserUuId: string): Observable<IAdminSellerView> {
    return this.cosClient.requestWithPrivileges('get', `/admin/user-account/seller/${sellerUserUuId}`);
  }

  public getBuyerUserAdminView(buyerUserUuId: string): Observable<IAdminBuyerView> {
    return this.cosClient.requestWithPrivileges('get', `/admin/user-account/buyer/${buyerUserUuId}`);
  }

  public getGeneralBusinessTermsHistory(): Observable<IGeneralBusinessTerms[]> {
    return this.cosClient.requestWithPrivileges('get', `/admin/legal/general-business-terms`);
  }

  public addGeneralBusinessTerms(generalBusinessTerms: IGeneralBusinessTerms): Observable<IGeneralBusinessTerms[]> {
    return this.cosClient.requestWithPrivileges('put', `/admin/legal/general-business-terms`, generalBusinessTerms);
  }

  public getBuyerSurveys(): Observable<ISurvey[]> {
    return this.cosClient.requestWithPrivileges('get', `/admin/survey/buyer/_all`);
  }

  public createBuyerSurvey(surveyToCreate: ISurvey): Observable<void> {
    return this.cosClient.requestWithPrivileges('put', `/admin/survey/buyer`, surveyToCreate);
  }

  public closeBuyerSurvey(survey: ISurvey): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/admin/survey/buyer/${survey.uuid}/close`);
  }

  public getSellerSurveys(): Observable<ISurvey[]> {
    return this.cosClient.requestWithPrivileges('get', `/admin/survey/seller/_all`);
  }

  public createSellerSurvey(surveyToCreate: ISurvey): Observable<void> {
    return this.cosClient.requestWithPrivileges('put', `/admin/survey/seller`, surveyToCreate);
  }

  public closeSellerSurvey(survey: ISurvey): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/admin/survey/seller/${survey.uuid}/close`);
  }

  public getVehicleDetailsByVin(vin: string): Observable<any> {
    return this.cosClient.requestWithPrivileges('get', `/admin/vehicle/vin/${vin}`);
  }

  public getAuctionComplaints(auction: IAuction): Observable<IAuctionComplaint[]> {
    return this.cosClient.requestWithPrivileges('get', `/admin/auction/${auction.uuid}/complaints`);
  }

  public deleteAuctionComplaint(auction: IAuction): Observable<void> {
    return this.cosClient.requestWithPrivileges('delete', `/admin/auction/${auction.uuid}/complaint`);
  }

  public resetAuctionComplaintTimeWindow(auction: IAuction): Observable<void> {
    return this.cosClient.requestWithPrivileges('put', `/admin/auction/${auction.uuid}/complaint/reset`);
  }

  public getComplaintProcessStartPossible(auction: IAuction): Observable<IAuctionComplaintPossibility> {
    return this.cosClient.requestWithPrivileges('get', `/admin/auction/${auction.uuid}/complaint/check`);
  }

  public setLastOfferBySeller(auction: IAuction): Observable<IAuction> {
    const lastOfferBySeller: ILastOfferBySeller = {
      lastOfferBySeller: auction.lastOfferBySeller,
      lastOfferBySellerNet: undefined,
    };

    return this.cosClient.requestWithPrivileges('put', `/internal/auction/${auction.uuid}/last-offer`, lastOfferBySeller);
  }

  public setCounterOfferForBuyer(auction: IAuction): Observable<IAuction> {
    return this.cosClient.requestWithPrivileges('post', `/internal/auction/${auction.uuid}/counter-offer-by-buyer`, {
      counterOffer: auction.counterOfferByBuyer,
    });
  }

  public listLastOfferAsInstantPurchase(auction: IAuction, runtimeInSeconds: number): Observable<IAuction> {
    return this.cosClient.requestWithPrivileges(
      'post',
      `/admin/auction/${auction.uuid}/reject/last-offer/instant-purchase?runTimeInSeconds=${runtimeInSeconds}`,
      undefined,
    );
  }

  public getUser(userId: string): Observable<IIdentityProviderUser> {
    return this.cosClient.requestWithPrivileges('get', `/admin/users/${userId}`);
  }

  public getBiddingAgentForAuction(auction: IAuction): Observable<IAuctionBiddingAgentTask> {
    return this.cosClient.requestWithPrivileges('get', `/admin/auction/${auction.uuid}/agent`);
  }

  public removeBiddingAgent(biddingAgent: IAuctionBiddingAgentTask): Observable<void> {
    return this.cosClient.requestWithPrivileges('delete', `/admin/auction/${biddingAgent._fk_uuid_auction}/${biddingAgent._fk_uuid_buyerUser}/agent`);
  }

  public createAuctionMistake(mistake: IAuctionMistake): Observable<IAuctionMistake> {
    return this.cosClient.requestWithPrivileges('post', `/admin/auction/${mistake._fk_uuid_auction}/mistake`, mistake);
  }

  public updateAuctionMistake(mistake: IAuctionMistake): Observable<IAuctionMistake> {
    return this.cosClient.requestWithPrivileges('put', `/admin/auction/${mistake._fk_uuid_auction}/mistake/${mistake.uuid}`, mistake);
  }

  public deleteAuctionMistake(mistake: IAuctionMistake): Observable<void> {
    return this.cosClient.requestWithPrivileges('delete', `/admin/auction/${mistake._fk_uuid_auction}/mistake/${mistake.uuid}`);
  }

  public createTestAuctions(params: any): Observable<IAuction[]> {
    return this.cosClient.requestWithPrivileges('post', `/debug/auction`, params);
  }

  public cancelDocumentShippingOrder(auction: IAuction): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/admin/auction/${auction.uuid}/document-shipping/cancel`);
  }

  public getEmailTemplates(): Observable<IEmailTemplateSettings[]> {
    return this.cosClient.requestWithPrivileges('get', `/admin/test-email/templates`);
  }

  public sendTestEmail(sendTestEmailRequest: ITestMailSendRequest): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/admin/test-email`, sendTestEmailRequest);
  }

  public setAuctionPrebookedServicesForBuyer(
    buyerUserUuid: string,
    auctionUuid: string,
    prebookedServices: IPrebookedService[],
  ): Observable<IPrebookedService[]> {
    return this.cosClient.requestWithPrivileges('put', `/internal/auction/${auctionUuid}/prebooked-services/${buyerUserUuid}`, prebookedServices);
  }

  public updateUserState(user: IGeneralUser): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/admin/user/${user.uuid}/state`, { state: user.state });
  }

  public getUserAuthActivity(user: IGeneralUser): Observable<IAuthenticationActivity[]> {
    return this.cosClient.requestWithPrivileges('get', `/admin/user/${user.uuid}/authentication-activity`);
  }

  public getUserTwoFactorStatus(userUuid: string): Observable<boolean> {
    return this.cosClient.requestWithPrivileges('get', `/admin/user/${userUuid}/two-factor-auth`);
  }

  public disableUserTwoFactorAuth(userUuid: string): Observable<void> {
    return this.cosClient.requestWithPrivileges('put', `/admin/user/${userUuid}/two-factor-auth/disable`);
  }

  public createVoucher(voucher: ICreateVoucherPayload): Observable<IVoucher> {
    return this.cosClient.requestWithPrivileges('post', `/admin/vouchers`, voucher);
  }

  public listVouchers(): Observable<IVoucher[]> {
    return this.cosClient.requestWithPrivileges('get', `/admin/vouchers`);
  }

  public getVoucher(voucherUuid: string): Observable<IVoucher> {
    return this.cosClient.requestWithPrivileges('get', `/admin/vouchers/${voucherUuid}`);
  }

  public listAssignmentsOfVoucher(voucherUuid: string): Observable<IVoucherAssignment[]> {
    return this.cosClient.requestWithPrivileges('get', `/admin/vouchers/${voucherUuid}/assignments`);
  }

  public listVouchersAssignedToUser(userUuid: string): Observable<IVoucherAssignment[]> {
    return this.cosClient.requestWithPrivileges('get', `/admin/vouchers/assignments/${userUuid}`);
  }

  public getVoucherAssignedToUser(voucherUuid: string, userUuid: string): Observable<IVoucherAssignment> {
    return this.cosClient.requestWithPrivileges('get', `/admin/vouchers/${voucherUuid}/assignments/${userUuid}`);
  }

  public assignVoucherToUser(voucherUuid: string, userUuid: string): Observable<IVoucherAssignment> {
    return this.cosClient.requestWithPrivileges('put', `/admin/vouchers/${voucherUuid}/assignments/${userUuid}`);
  }

  public reauctionAuction(auctionId: string): Observable<IAuction> {
    return this.cosClient.requestWithPrivileges('put', `/admin/auction/${auctionId}/clone-waiting-for-payment`);
  }

  public createInvoice(params: ICreateInvoiceViaFormRequest): Observable<IInvoice> {
    return this.cosClient.requestWithPrivileges('post', `/admin/invoice`, params).pipe(map(response => response.dto));
  }

  public createCreditNoteXML(params: ICreateCreditNoteXMLPayload): Observable<ICreateCreditNoteXMLResponse> {
    return this.cosClient.requestWithPrivileges('post', `/admin/invoice/e-invoicing/credit-note`, params);
  }
}
